<template>
    <div>
        <div class="divide-bar">
            <div class="divide-bar-stage" id="divide-bar-stage"
                 @touchstart="handleEvent" @touchmove="handleEvent" @touchend="handleEvent">
                <div class="divide-bar-container" :class="{ easein: !isTouch }"
                     :style="{ transform: 'rotateX(' + realRotate + 'deg)' }">
                    <div class="divide-bar-item"
                         :key="index"
                         :style="{ transform: 'rotateX(' + rotateRate * index + 'deg) translateZ(' + -zDistance +
                         'px)'}"
                         :class="{'active' : item === map, 'first-child': index === 0,'pickerOn':index==listOn }"
                         v-for="(item,index) in pickerList">
                        {{item[val]}}
                    </div>
                </div>
                <p class="divideLine"></p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ScrollPiker',
        data: function () {
            return {
                itemHeight: 0,//每个div高度
                rotateRate: 0,//每次旋转角度
                itemNum: 18,//单圈最大容纳数目
                zDistance: 0,//轴距
                realRotate: 0,//实际旋转度数
                lastRotate: 0,
                eachOffset: 0,
                touchStart: 0,
                moveDistance: 0,
                isTouch: false,
                listOn: 0
            };
        },
        props: {
            pickerList: {
                type: Array,
                default: function () {
                    return new Array();
                }
            },//picker数据源
            map: {
                type: Object,
                default: function () {
                    return {};
                }
            },//默认对象
            val: {
                type: String,
                default: 'val'
            },//数据文字取值
            keys: {
                type: String,
                default: 'keys'
            },//数据key取值
        },
        watch: {
            pickerList: function () {
                this.init();
            },
            map: function (val) {
                let thiz = this;
                this.pickerList.forEach(function (item, index) {
                    if (val === item) {
                        thiz.select(index);
                    }
                });
            }
        },
        beforeMount: function () {
            var styleNode = document.createElement('style');
            styleNode.setAttribute('id', this.$options._componentTag)
            // styleNode.innerText = style;
            document.body.appendChild(styleNode);
        },
        mounted: function () {
            let thiz = this
            this.init();
            // document.getElementById('divide-bar-stage');
            this.pickerList.forEach(function (item, index) {
                if (thiz.map === item) {
                    thiz.select(index);
                }
            });
        },
        beforeDestroy: function () {
            var styleNode = document.getElementById(this.$options._componentTag);
            document.body.removeChild(styleNode);
        },
        methods: {
            init: function () {
                let _this = this;
                let dom = document.getElementsByClassName('first-child');
                if (!dom) {
                    setTimeout(function () {
                        _this.init();
                    }, 10);
                } else {
                    for (let i = 0; i < dom.length; i++) {
                        if (dom[i].clientHeight) {
                            this.itemHeight = dom[i].clientHeight;
                            break;
                        }
                    }
                    if (this.pickerList.length > this.itemNum) {
                        this.itemNum = this.pickerList.length;
                    }
                    this.rotateRate = 360 / this.itemNum;
                    this.zDistance = this.itemHeight / (Math.tan(this.rotateRate / 360 * Math.PI) * 2);
                    // if (JSON.stringify(this.map) == '{}' && this.pickerList[0]) {
                    //     this.$emit('change-picker', this.pickerList[0]);
                    // }
                    if (this.pickerList[0]) {
                        this.$emit('change-picker', this.pickerList[0]);
                    }
                }
            },
            select: function (index) {
                if (index > this.pickerList.length - 1) {
                    return;
                }

                var r = this.rotateRate * index + this.realRotate;
                var i = r % 360;

                if (i >= 0 && i <= 180) {
                    this.realRotate = this.realRotate - i;
                } else if (i >= 180 && i <= 360) {
                    this.realRotate = this.realRotate - i + 360;
                }
                if (i < 0 && i >= -180) {
                    this.realRotate = this.realRotate - i;
                } else if (i <= -180 && i >= -360) {
                    this.realRotate = this.realRotate - i - 360;
                }
                this.$emit('change-picker', this.pickerList[index]);
            },
            handleEvent: function (e) {
                //e.preventDefault();
                //console.log(e);
                if (e.type === 'touchstart') {
                    this.touchStart = e.touches[0].clientY;
                    this.moveDistance = 0;
                    this.lastRotate = -this.realRotate;
                    this.isTouch = true;
                }
                if (e.type === 'touchmove') {
                    e.preventDefault();
                    this.moveDistance = this.touchStart - e.touches[0].clientY;
                    this.eachOffset = this.moveDistance / this.itemHeight * this.rotateRate;
                    // var rotate = this.lastRotate + this.eachOffset;
                    this.realRotate = -(this.lastRotate + this.eachOffset * 3);

                    if (this.pickerList.length < this.itemNum) {
                        if (this.realRotate > this.rotateRate / 2) {
                            this.realRotate = this.rotateRate / 2;
                        }

                        if (this.realRotate < -this.rotateRate * (this.pickerList.length - 1) - this.rotateRate / 2) {
                            this.realRotate = -this.rotateRate * (this.pickerList.length - 1) - this.rotateRate / 2;
                        }
                    }
                }
                if (e.type === 'touchend') {
                    var r = this.realRotate % this.rotateRate;
                    var c = this.realRotate / this.rotateRate;
                    if (c > 0) {
                        c = this.pickerList.length - (parseInt(c) % this.itemNum);
                    } else {
                        c = parseInt(c) % this.itemNum;
                    }
                    var index;
                    if (this.realRotate >= 0) {
                        if (r > this.rotateRate / 2) {
                            this.realRotate = this.realRotate + this.rotateRate - r;//滑动超过一半
                            if (c > 0) {
                                c--;
                            } else {
                                c++;
                            }
                        } else {
                            this.realRotate = this.realRotate - r;
                        }

                        if (this.pickerList.length < this.itemNum) {
                            index = 0
                        } else {
                            index = c % this.itemNum;
                        }
                    } else {
                        if (r + this.rotateRate / 2 < 0) {
                            this.realRotate = this.realRotate - this.rotateRate - r;//滑动超过一半
                            if (c > 0) {
                                c++
                            } else {
                                c--;
                            }
                        } else {
                            this.realRotate = this.realRotate - r;
                        }
                        index = Math.abs(c) % this.itemNum;
                    }
                    this.listOn = index;
                    this.isTouch = false;
                    this.$emit('change-picker', this.pickerList[index]);
                }
                //console.log(this.moveDistance);
            }
        }
    }
</script>

<style scoped lang="scss">
    

    .divide-bar-stage {
        display: flex;
        align-items: center;
        justify-content: center;
        perspective: 111111;
        -webkit-perspective: 111111;
        overflow: hidden;
        height: 2rem;
    }

    .divide-bar-container {
        position: relative;
        width: 100%;
        transform-style: preserve-3d;
        height: .4rem;
    }

    .divide-bar-container.easein {
        transition: transform 0.5s ease;
    }

    .divide-bar-item {
        position: absolute;
        width: 100%;
        text-align: center;
        backface-visibility: hidden;
        color: #a4a4a4;
        line-height: .4rem;
        height: .4rem;
    }

    .divide-bar-item.active {
        color:  var(--themeColor);
    }

    .pickerOn {
        /*border-top: 1px solid #F5F5F5;*/
        /*border-bottom: 1px solid #F5F5F5;*/
        color: #000;
    }

    .divideLine {
        position: absolute;
        height: .4rem;
        width: 100%;
        border-top: 1px solid #F5F5F5;
        border-bottom: 1px solid #F5F5F5;
    }
</style>